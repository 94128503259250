import request from '@/utils/request'


// 查询city列表
export function listCity(query) {
  return request({
    url: '/city/city/list',
    method: 'get',
    params: query
  })
}

// 查询city分页
export function pageCity(query) {
  return request({
    url: '/city/city/page',
    method: 'get',
    params: query
  })
}

// 查询city详细
export function getCity(data) {
  return request({
    url: '/city/city/detail',
    method: 'get',
    params: data
  })
}

// 新增city
export function addCity(data) {
  return request({
    url: '/city/city/add',
    method: 'post',
    data: data
  })
}

// 修改city
export function updateCity(data) {
  return request({
    url: '/city/city/edit',
    method: 'post',
    data: data
  })
}

// 删除city
export function delCity(data) {
  return request({
    url: '/city/city/delete',
    method: 'post',
    data: data
  })
}
